<template>
    <div>
        <v-snackbar v-model="snackbar" :top="true" :color="color" :timeout="6000">
            <span v-if="snackbar_msg!=null">{{ snackbar_msg }}</span>
            <v-btn dark text @click="snackbar = false">Close</v-btn>
        </v-snackbar>
        <v-row class="bradcrumb">
            <router-link :to="{name: 'home' }" class="root">
                Dashboard
            </router-link>
            /

            <router-link :to="{name: 'rolelink' }" class="root">
                Librarian
            </router-link>
            /
            <router-link :to="{name: 'reports' }" class="root">
                Reports
            </router-link>
            /
            <span class="child">Over Due Report </span>
        </v-row>
        <br />

        <v-card class="card">
            <v-card-title class="heading justify-center"> Over Due Members Report</v-card-title>

           <!-- <div class="checkbox">
                 <v-row style="margin:0px 10px 0px 10px">
                    <v-col><v-checkbox v-model="selected" label="Accession No" value="AccessionNo"></v-checkbox></v-col>
                    <v-col><v-checkbox v-model="selected" label="Category" value="Category"></v-checkbox></v-col>
                    <v-col><v-checkbox v-model="selected" label="Title" value="Title"></v-checkbox></v-col>
                    <v-col><v-checkbox v-model="selected" label="Author" value="Author"></v-checkbox></v-col>
                    <v-col><v-checkbox v-model="selected" label="Publisher" value="Publisher"></v-checkbox></v-col>
                   
                </v-row >
                                <v-row  style="margin:0px 10px 0px 10px">
                   <v-col><v-checkbox v-model="selected" label="Library ID" value="LibraryId"></v-checkbox></v-col>
                    <v-col><v-checkbox v-model="selected" label="Member Name" value="memberName"></v-checkbox></v-col>
                    <v-col><v-checkbox v-model="selected" label="Start Date" value="startDate"></v-checkbox></v-col>
                    <v-col><v-checkbox v-model="selected" label="Due Date" value="dueDate"></v-checkbox></v-col>
                    <v-col><v-checkbox v-model="selected" label="Late Days" value="lateDays"></v-checkbox></v-col>
                
                    
                </v-row> -->
               <!-- <center> <v-btn dense color="info" @click="fetch" style="margin:10px">Fetch</v-btn></center> -->
            <!-- </div> -->


            <div class="">
                <div class="add-section">
                    <div class="d-flex">
                        <v-col>
                            <label class="add-text">Series</label>{{dept}}
                            <v-autocomplete v-model="series" placeholder="Select Series" :items="series_data" item-text="name" item-value="id" outlined dense class="text" :rules="[rules.required]" clearable required></v-autocomplete>
                        </v-col>
                        <v-col>
                            <label>Start Date</label>

                            <v-menu v-model="fromDateMenu" :close-on-content-click="false" transition="scale-transition" offset-y full-width max-width="290px" min-width="290px">
                                <template v-slot:activator="{ on }">
                                    <v-text-field class="text" v-model="startDate" placeholder="Select Start Date" append-icon="event" persistent-hint dense readonly outlined v-on="on"></v-text-field>
                                </template>
                                <v-date-picker v-model="startDate" no-title @input="fromDateMenu = false"></v-date-picker>
                            </v-menu>
                        </v-col>

                        <v-col>
                            <label>End Date</label>

                            <v-menu v-model="fromDateMenu1" :close-on-content-click="false" transition="scale-transition" offset-y full-width max-width="290px" min-width="290px">
                                <template v-slot:activator="{ on }">
                                    <v-text-field class="text" v-model="endDate" placeholder="Select End Date" append-icon="event" persistent-hint dense readonly outlined v-on="on"></v-text-field>
                                </template>
                                <v-date-picker v-model="endDate" no-title @input="fromDateMenu1 = false"></v-date-picker>
                            </v-menu>
                        </v-col>

                        <v-col>
                            <label class="add-text">User Type</label>
                            <v-autocomplete v-model="usertype" placeholder="Select User Type" :items="usertype_data" item-text="name" item-value="id" outlined class="text" dense :rules="[rules.required]" clearable required></v-autocomplete>
                        </v-col>
                         <v-col>
                            <!-- <label class="add-text">Show Return Book</label> -->
                            <v-checkbox label="Show Return Book" v-model="showreturn" ></v-checkbox>
                        </v-col>
                    </div>

                    <div>
                        <center>
                            <v-btn color="success" class="mb-4" @click="overdueData">Fetch Data</v-btn>
                        </center>
                    </div>
                </div>
                        <!-- <div class="d-btn" v-if="bl_data.length > 0">              
                            <div>
                                <v-btn color="primary" @click="generatePDF"><span class="mdi mdi-download mr-2" style="font-size: 20px;"></span> Download Report</v-btn>
                            </div>
                            {{bl_data}}
                        </div> -->  
            </div>
        </v-card>
        <br>
        <div>
                    <v-row justify="end" class="mr-5">
        <v-btn v-if="bl_data.length != 0"  @click="exportexcel()" color="success" dark class="mb-2">
          <v-icon dark>mdi-file-excel</v-icon>
        </v-btn>
      </v-row>
         <div v-if="load">
  <v-skeleton-loader
          v-bind="attrs"
          type="three-line,  article"
        ></v-skeleton-loader>

  </div>
  <div v-else>
      <v-simple-table id="exceltable"  class="elevation-1" :search="search" v-if="bl_data.length!=0">
       
        <template v-slot:default>
          <thead>
            <tr>
              <th class="text-left"> Sr. No. </th>
              <th class="text-left"> Accession No. </th>
              <th class="text-left"> Title</th>
              <th class="text-left"> Author</th>
              <th class="text-left"> PRN No./Emp Id</th>
              <th class="text-left"> Name</th>
              <th class="text-left"> Year</th>
              <th class="text-left"> Department</th>
              <th class="text-left"> Borrow Date</th>
              <th class="text-left"> Due Date</th>
              <th class="text-left"> Return Date</th>
              <th class="text-left"> Late Days</th>
              <th class="text-left"> Fine (Rs.)</th>
            </tr>
          </thead>
          <tbody>
          
            <tr
              v-for="item in bl_data"
              :key="item.id"
            >
              <td>{{ item.srno }}</td>
              <td>{{ item.accession }}</td>
              <td>{{ item.title }}</td>     
              <td >
            
                 {{ item.author }}            
              </td>
              <td>{{ item.PRN_EMP}}</td>
              <td>{{ item.name}}</td>
              <td>{{ item.year}}</td>
              <td>{{ item.dept}}</td>
              <td><span v-if="item.brw_date != null">{{ item.brw_date.split("T")[0]}}</span>
              <span v-else>-</span>
              </td>
              <td> <span v-if="item.duedate != null">{{ item.duedate.split("T")[0]}}</span>
              <span v-else>- </span>
              </td>
               <td><span v-if="item.return_date != null && item.return_date != '-'">{{ item.return_date.split("T")[0]}}</span>
              <span v-else>-</span>
              </td>
              <td>{{ item.late_days}}</td>
              <td>{{ item.fine}}</td>
            </tr>
          </tbody>
        </template>
      </v-simple-table>
  </div>
        </div>

               
     
    </div>
</template>

<script>
    import axios from "axios";
    import moment from "moment";
    import {table2excel} from "../jquery.table2excel";
    import { jsPDF } from "jspdf";
    import "jspdf-autotable";

    import "@/assets/css/color.css"; // css needs to be imported for each dashboard
    export default {
        data: () => ({
            showreturn:false,
            load:false,
            fromDateMenu1 : false,
            dept: null,
            series:null,
            fromDateMenu1: false,
            usertype: null,
            valid: false,
            overlay: false,
            userid: "",
            bookitemid: "",
            selected:[],
            bl_data:[],
            errorMsg: {
                roleLinkId: false,
            },
            rules: {
                required: (value) => !!value || "Required.",
            },
            snackbar: false,
            snackbar_msg: "",
            org: "",
            dialog: false,
            fromDateMenu: false,
            fromDateVal: [],
            startDate: "",
            endDate: "",
            overDueData: [],
            dept_data: [],
            series_data:[],
            usertype_data: [],
            minDate: "2020-01-05",
            maxDate: new Date(),
            dept_name: "",
            user_type: "",
             headers: [
          { text: 'Sr.No.', value: 'srn' },
          { text: 'Accession No.', value: 'accession' },
          { text: 'Title', value: 'title' },
          { text: 'Author', value: 'author' },
          { text: 'Name', value: 'name' },
           { text: 'Class', value: 'year' },
          { text: 'Department', value: 'dept' },
          { text: 'Borrow date', value: 'brw_date' },
          { text: 'Return Date', value: 'return_date' },
          { text: 'Due Date', value: 'duedate' },
          { text: 'Late Days', value: 'late_days' },
          { text: 'Fine', value: 'fine' },
        ],
       
            csv_header_data:[],
            table_header_data:[],
            filters: {
                acc_no:[],
authors:[],
duedate:[],
lateDays:[],
member_name:[],
publisher:[],
srno:[] ,
startdate:[] ,
title:[]
            },
            colData:{}
        }),
        computed: {
            fromDateDisp() {
                return this.fromDateVal;
                // format/do something with date
            },
            filteredDesserts() {
                return this.bl_data.filter((d) => {
                    return Object.keys(this.filters).every((f) => {
                        return this.filters[f].length < 1 || this.filters[f].includes(d[f]);
                    });
                });
            },
            dateRangeText() {
                return this.dates.join(" ~ ");
            },
        },

        mounted() {
            this.onLoad();
        },
        methods: {
            getFiltered(e) {
                console.log(e);
                if(e?.length == 0)
                {
                    this.showSnackbar("#b71c1c", "No Record matching with your input/ filter"); 
                }
                this.filterArr = e;
            },
            
            onlyUnique(value, index, self) {
                if(value != null && value != "" && value)
                    return self.indexOf(value) === index;
            },

            columnValueList(val) {
                var data = this.bookList.map((d) => d[val]);
                data = data.filter(this.onlyUnique);
                return data
            },

            generatePDF() {
                const columns = this.csv_header_data
                const doc = new jsPDF({
                    orientation: "l",
                    unit: "in",
                    format: "letter",
                });

                // text is placed using x, y coordinates
                //   var img = 'https://www.vierp.in/logo/edu+camp-01.png'
                //   doc.addImage(img,'PNG', 0, 0, 180, 160);
                doc.setFontSize(10).text("Pimpri Chinchwad Education Trust", 0.5, 0.3);
                doc.setFontSize(12).text("Pimpri Chinchwad College of Engineering", 0.5, 0.5);
                doc.setFontSize(12).text("Central Library", 0.5, 0.7);

                doc.setFontSize(9).text("Record No", 8, 0.3);
                doc.setFontSize(9).text("Revision", 8, 0.4);
                doc.setFontSize(9).text("Date", 8, 0.5);
                doc.setFontSize(10).text("Department :" + this.dept_name, 8, 0.8);
                doc.setFontSize(10).text("User Type : " + this.user_type, 8, 1);

                let d1 = moment(String(this.startDate)).format("DD-MM-YYYY");
                let d2 = moment(String(this.endDate)).format("DD-MM-YYYY");

                // doc.setFontSize(12).text("Over Dues Members List - Reports - On Yearly Basis Between"+ this.startDate+'and'+ this.endDate, 0.5, 1.0);
                doc.setTextColor("blue").text("Over Dues Members List - Reports - On Yearly Basis Between " + " " + d1 + " " + "and" + " " + d2, 0.5, 1.0);
                doc.setLineWidth(0.01).line(0.5, 1.1, 10.4, 1.1);
                doc.autoTable({
                    columns,
                    body: this.filteredDesserts,
                    margin: { left: 0.5, top: 1.25 },
                });

                doc.setTextColor(0, 0, 255).save(`Over Dues Members List.pdf`);
            },

            overdueData() {
               // alert(+this.series+" | "+this.usertype+" |"+this.startDate+" | "+this.endDate);
                this.overlay = true;
                let abc = Object.assign({}, this.selected);

                // for(let i = 0;i<this.selected.length;i++){
                //     let key = abc[i]
                //     this.colData[key] = key
                // }

                if (this.startDate == null || this.endDate == null || this.usertype == null || this.series == null) {
                    this.showSnackbar("#b71c1c", "All fields are required!!");
                } else {
                    var showreturn="false";
                   if(this.showreturn)
                            showreturn="true";
                    const data = {
                        isreturn:showreturn,
                        firstDate: moment(String(this.startDate)).format("DD-MM-YYYY"),
                        lastDate: moment(String(this.endDate)).format("DD-MM-YYYY"),
                        series_id: this.series,
                        user_id: this.usertype,
                        
                        // colData: this.colData
                    };
                    this.load=true;
                    axios
                        .post("/Librarian/overdueData", data)
                        .then((res) => {
                            if (res.data.msg == "200") {
                                this.overlay = false;
                                this.overDueData = res.data.overdue_data;
                                this.dept_name = res.data.dept;
                                this.user_type = res.data.usertype;
                                this.bl_data = res.data.bl_data;
                                                        this.load=false;
                                // this.csv_header_data = res.data.csv_header_data
                                // this.table_header_data = res.data.table_header_data
                                // this.headers = this.table_header_data

                                // for(let i = 0;i<this.table_header_data.length;i++){
                                //         let key = this.table_header_data[i].value
                                //         this.filters[key] = []
                                //     }
                                if (this.bl_data.length == 0) {
                                    this.showSnackbar("#b71c1c", "No data available ");
                                }
                            }
                        })
                        .catch((error) => {
                            this.showSnackbar("#b71c1c", "Something went wrong.."); // show snackbar
                            window.console.log(error);
                            this.load=false;
                        })
                        .finally(() => {});
                }
            },
             exportexcel() {    
        $("#exceltable").table2excel({  
          name: "Worksheet Name",
          filename: "Over_Due_Report", //do not include extension
          fileext: ".xls" // file extension
        });        
      },
            onLoad() {
                this.overlay = true;
                axios
                    .post("/Librarian/getdeptData")
                    .then((res) => {
                        if (res.data.msg == "200") {
                            this.overlay = false;
                            this.endDate = new Date().toJSON().slice(0,10);
                     //this.selectedfromdate = new Date().setMonth(this.selectedtodate.getMonth() - 1);
                     var d = new Date();
                      d.setMonth(d.getMonth() - 1);
                      this.startDate = d.toJSON().slice(0,10);
                            this.dept_data = res.data.dept_data;
                             this.series_data = res.data.series_data;
                            this.usertype_data = res.data.usertype_data;

                            this.series_data.push({
                            name: 'All',
                            text: ''
                            })
                            this.series='All'

                            this.usertype_data.push({
                            name: 'All',
                            text: ''
                            })
                            this.usertype='All'
                            }
                    })
                     


                        
                    .catch((error) => {
                        this.showSnackbar("#b71c1c", "Something went wrong..."); // show snackbar
                        window.console.log(error);
                    })
                    .finally(() => {
                        // var overlay = false;
                    });
            },
            showSnackbar(clr, msg) {
                this.snackbar = true;
                this.color = clr;
                this.snackbar_msg = msg;
            },
            close() {
                this.dialog = false;
                // this.$refs.form.reset()
                setTimeout(() => {
                    this.editedItem = Object.assign({}, this.defaultItem);
                    this.editedIndex = -1;
                }, 300);
            },
        },
    };
</script>
<style scoped>
    .v-text-field {
        padding-top: 0px;
        margin-top: 0px;
    }
    .v-icon-size {
        font-size: 20px;
    }
    .edit-avatar {
        transition: 0.3s;
        border: 1px solid #b0bec5;
    }
    .edit-avatar:hover {
        background-color: #b0bec5;
    }
    .edit-v-icon {
        padding-left: 6px;
        font-size: 15px;
    }
    .edit-v-icon:hover {
        color: white;
    }
    .fields {
        padding: 0px !important;
    }
    .cus-card {
        background: #d3d3d33d;
        margin: 1rem;
        padding: 1rem;
        border: 2px solid gray;
    }
    .c-label {
        font-weight: bold;
    }
    .add-section {
        display: block;
        margin: 1rem;
    }
    .text >>> .v-input__slot {
        background: white !important;
        border-color: hsla(190, 99%, 30%, 1) !important;
    }
    .elevation-1 /deep/ tr {
        white-space: nowrap !important;
    }
    .d-btn {
        display: flex;
        justify-content: flex-end;
        margin: 0.5rem;
    }
        .checkbox{
        margin: 1rem;
    border: 1px solid;
    background: #d3d3d380;
    }
    .checkbox .box{
        margin-top: 5px;
    }
        .checkbox /deep/ .v-input--selection-controls{
        margin-top: 0px  !important;
    }
</style>
